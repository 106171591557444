import React, { useEffect, useState } from 'react'
import { getUpsertDefault, useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import LoaderTransparent from '../common/LoaderTransparent'
import Button from '@material-ui/core/Button'
import { BoxWrapper } from '../stylesComponents/Tags'
import { SelectFieldValue200pxWrapper } from '../stylesComponents/UpsertCommon'
import FieldValueAutocomplete from '../fieldValue/FieldValueAutocomplete'
import { getEntities } from '../../service/entityApiService'
import {
  generateInitialUpsertUserParentEntityFieldValues,
  getUpsertUserParentEntityFieldValues,
  getUpsertUserParentEntityFieldValuesValidated,
} from '../../context/UpsertUserParentEntityFieldValues'
import { getEntity, setEntity } from '../../api/userEtherApi'
import { getUserIdFromAddress } from '../../api/systemEtherApi'
import userApiService, { getUsers } from '../../service/userApiService'
import toastrService from '../../service/toastrService'

const UserAclUpsertWrapper = styled.div`
  position: relative;
`

const ButtonsWrapper = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 100px 100px;
  justify-content: flex-end;
`

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 30px 0;
`


export default () => {
  const { upsertUser, paginationUser, setPaginationUser } = useGlobalContext()

  const [upsertState, setUpsertState] = useState(getUpsertDefault())
  const [parentEntityId, setParentEntityId] = useState(getUpsertDefault())
  const [upsertUserParentEntityFieldValues, setUpsertUserParentEntityFieldValues] = useState(getUpsertUserParentEntityFieldValues())

  const setInitRequestStatus = requestStatus => {
    setUpsertState({
      ...upsertState,
      initRequestStatus: requestStatus,
    })
  }

  const [entityOptions, setEntityOptions] = useState([])

  const init = async () => {
    setInitRequestStatus(RequestStatusEnum.loading)

    const paginationResponse = await getEntities({
      page: 0,
      rowsPerPage: 100,
    })
    setEntityOptions(
      paginationResponse.data.map(item => {
        return {
          value: item.id,
          label: item.entityName,
        }
      }),
    )

    if (upsertUser.id) {
      const parentEntityId = await getEntity(upsertUser.id)
      if (parentEntityId) {
        setParentEntityId(parentEntityId)
        setUpsertUserParentEntityFieldValues(
          generateInitialUpsertUserParentEntityFieldValues(parentEntityId, upsertUserParentEntityFieldValues),
        )
      }
    }

    setInitRequestStatus(RequestStatusEnum.success)
  }

  useEffect(() => {
    init()
  }, [])

  const setSubmitRequestStatus = requestStatus => {
    setUpsertState({
      ...upsertState,
      submitRequestStatus: requestStatus,
    })
  }

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertUserParentEntityFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertUserParentEntityFieldValues(fieldValuesClone)

    const { fieldValuesValidated } = getUpsertUserParentEntityFieldValuesValidated(fieldValuesClone)

    setUpsertUserParentEntityFieldValues(fieldValuesValidated)
  }

  const onSubmit = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getUpsertUserParentEntityFieldValuesValidated(upsertUserParentEntityFieldValues)

    if (!isValid) {
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertUserParentEntityFieldValues(fieldValuesValidated)
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    const userId = await getUserIdFromAddress(upsertUser.data.address)
    const parentEntityIdValue = upsertUserParentEntityFieldValues.parentEntityId.value
    await setEntity(userId, parentEntityIdValue)

    await userApiService.updateUser({
      parentEntityId: parentEntityIdValue,
    }, upsertUser.id)

    const paginationResponse = await getUsers({
      searchText: paginationUser.searchText,
      filters: paginationUser.filters,
      page: paginationUser.page,
      rowsPerPage: paginationUser.rowsPerPage,
    })

    setPaginationUser({
      ...paginationUser,
      data: paginationResponse.data,
    })

    setParentEntityId(parentEntityIdValue)

    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const onSubmitWithErrorHandling = async () => {
    try {
      await onSubmit()
    } catch (error) {
      toastrService.error(error.message)
      console.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const isLoading = () => {
    return [upsertState.submitRequestStatus, upsertState.initRequestStatus].includes(RequestStatusEnum.loading)
  }

  const onCancel = () => {
    setUpsertUserParentEntityFieldValues(
      generateInitialUpsertUserParentEntityFieldValues(parentEntityId, upsertUserParentEntityFieldValues),
    )
  }

  const isAclRoleChanged = () => {
    return parentEntityId && parentEntityId !== upsertUserParentEntityFieldValues.parentEntityId.value
  }

  return (
    <UserAclUpsertWrapper>
      <LoaderTransparent active={isLoading()}>
        <BoxWrapper>

          <h4>Parent Entity</h4>

          <FormWrapper>

            <SelectFieldValue200pxWrapper>
              <FieldValueAutocomplete
                value={upsertUserParentEntityFieldValues.parentEntityId.value}
                name="parentEntityId"
                label="Parent Entity"
                options={entityOptions}
                withError
                error={upsertUserParentEntityFieldValues.parentEntityId.error}
                onFieldUpdate={onFieldUpdate}
              />
            </SelectFieldValue200pxWrapper>

            <ButtonsWrapper>

              <Button
                disabled={!(isAclRoleChanged())}
                variant="outlined" size="medium" color="secondary"
                onClick={onCancel}>
                Cancel
              </Button>

              <Button disabled={!(isAclRoleChanged())}
                      variant="outlined" size="medium" color="primary"
                      onClick={onSubmitWithErrorHandling}>
                Save
              </Button>
            </ButtonsWrapper>
          </FormWrapper>
        </BoxWrapper>

      </LoaderTransparent>

    </UserAclUpsertWrapper>
  )
}
