import React from 'react'
import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import GroupIcon from '@material-ui/icons/Group'
import GavelIcon from '@material-ui/icons/Gavel'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import WorkIcon from '@material-ui/icons/Work'
import SettingsIcon from '@material-ui/icons/Settings'
import PolicyIcon from '@material-ui/icons/Policy'
import PaidIcon from '@material-ui/icons/MoneyOff';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { useHistory, useLocation } from 'react-router-dom'
import { signOut } from '../service/authenticationApiService'
import RequestStatusEnum from '../enums/RequestStatusEnum'
import { useGlobalContext } from '../context/GlobalContext'
import BarChartIcon from '@material-ui/icons/BarChart';

const DrawerWrapper = styled.div`
  width: 250px;
`

const Text = styled.span`
  font-weight: ${props => props.bold ? 'bold' : 'initial'};
`

const NavbarItem = ({ path, location, label, Icon }) => {
  const history = useHistory()
  const isActive = path === location.pathname
  return (
    <ListItem button onClick={() => {
      history.push(path)
    }}>
      <ListItemIcon style={{ color: isActive ? 'black' : 'gray' }}><Icon color='inherit'/></ListItemIcon>
      <ListItemText><Text bold={location.pathname === path}>{label}</Text></ListItemText>
    </ListItem>
  )
}

export default () => {
  const { setCurrentUser } = useGlobalContext()

  const location = useLocation()

  const onSignOut = async () => {
    await signOut();
    setCurrentUser({
      data: null,
      requestStatus: RequestStatusEnum.success,
    })
  }

  return (
    <div>
      <Drawer variant="permanent" anchor='left'>
        <DrawerWrapper role="presentation">
          <List>
            <NavbarItem path='/users' location={location} label='Users' Icon={GroupIcon}/>
            <NavbarItem path='/simple-policies' location={location} label='Simple Policies' Icon={GavelIcon}/>
            <NavbarItem path='/entities' location={location} label='Entities' Icon={PermIdentityIcon}/>
            <NavbarItem path='/positions' location={location} label='Positions' Icon={BarChartIcon}/>
            <NavbarItem path='/trade' location={location} label='Trade' Icon={CompareArrowsIcon}/>
            <NavbarItem path='/jobs' location={location} label='Jobs' Icon={WorkIcon}/>
            <NavbarItem path='/system' location={location} label='System' Icon={SettingsIcon}/>
            <NavbarItem path='/migration' location={location} label='Migration' Icon={PolicyIcon}/>
            <NavbarItem path='/fee' location={location} label='Fee Schedule' Icon={PaidIcon}/>
          </List>

          <Divider/>

          <List>
            <ListItem button onClick={onSignOut}>
              <ListItemIcon><ExitToAppIcon/></ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItem>
          </List>

        </DrawerWrapper>
      </Drawer>
    </div>
  )
}
