import React, { useEffect } from 'react'
import { Redirect, BrowserRouter, Switch, Route, useHistory } from 'react-router-dom'

import Login from '../components/Login'
import RequestStatusEnum from '../enums/RequestStatusEnum'
import UserPagination from '../components/user/UserPagination'
import JobPagination from '../components/job/JobPagination'
import EntityPagination from '../components/entity/EntityPagination'
import PositionsPagination from '../components/positions/PositionsPagination'
import Navbar from '../components/Navbar'
import styled from 'styled-components'
import { useGlobalContext } from '../context/GlobalContext'
import userApiService from '../service/userApiService'
import { hasViewRights, signOut } from '../service/authenticationApiService'
import { getSettings } from '../service/settingApiService'
import PolicySimplePagination from '../components/policySimple/PolicySimplePagination'
import Trade from '../components/trade'
import { getNetwork } from '../api/web3'
import SettingUpsert from '../components/setting/SettingUpsert'
import MigrationUpsert from '../components/migration/MigrationUpsert'
import GlobalErrorLandingPage from '../components/globalErrorLandingPage/GlobalErrorLandingPage'
import FeeScheduleUpsert from '../components/fee/FeeScheduleUpsert'

const Content = styled.div`
  height: 100vh;
  overflow: hidden;
`

const PrivateRouteContentWrapper = styled.div`
  width: calc(100% - 250px);
  float: right;
  height: 100vh;
  overflow: hidden;
`

const PrivateRoutePropsMapping = ({ ...rest }) => {
  const {
    currentUser, setCurrentUser, setCurrentSetting, setSettings,
    globalErrorMessage, setGlobalErrorMessage,
  } = useGlobalContext()
  return (
    <PrivateRoute
      currentUser={currentUser}
      setCurrentUser={setCurrentUser}
      setCurrentSetting={setCurrentSetting}
      setSettings={setSettings}
      globalErrorMessage={globalErrorMessage}
      setGlobalErrorMessage={setGlobalErrorMessage}
      {...rest}
    />
  )
}

const PrivateRoute = React.memo(({
                                   currentUser, setCurrentUser, setCurrentSetting, setSettings,
                                   globalErrorMessage, setGlobalErrorMessage,
                                   component: Component, ...rest
                                 }) => {
  const history = useHistory()

  const goToLogin = () => {
    history.push('/login')
  }

  const syncSettings = async () => {
    const settings = await getSettings()

    setSettings({
      data: settings,
      requestStatus: RequestStatusEnum.success,
    })

    const network = await getNetwork()
    const networkId = network.chainId.toString()
    const currentSetting = settings.find(setting => setting.networkId === networkId)
    if (currentSetting === undefined) {
      setGlobalErrorMessage(`Settings are missing for current networkId. NetworkId is ${networkId}`)
      return
    }

    const settingsClone = {
      ...currentSetting,
      supportedTokensMap: currentSetting.supportedTokens.reduce((acc, supportedToken) => {
        acc[supportedToken.address] = supportedToken.name
        return acc
      }, {}),
      supportedTokensIdsMap: currentSetting.supportedTokens.reduce((acc, supportedToken) => {
        acc[supportedToken.id] = supportedToken.name
        return acc
      }, {}),
    }
    setCurrentSetting({
      data: settingsClone,
      requestStatus: RequestStatusEnum.success,
    })
  }


  const syncCurrentUser = async () => {

    let user
    try {
      user = await userApiService.getCurrentUser()
      if (!hasViewRights(user)) {
        goToLogin()
        return
      }
      setCurrentUser({
        data: user,
        requestStatus: RequestStatusEnum.success,
      })
      await syncSettings()
    } catch (e) {
      goToLogin()
    }
  }

  useEffect(() => {
    syncCurrentUser()
  }, [])

  if (currentUser.requestStatus !== RequestStatusEnum.success) {
    return <p>Loading</p>
  }

  if (globalErrorMessage) {
    return (
      <GlobalErrorLandingPage/>
    )
  }

  return (
    <>
      <Navbar/>
      <PrivateRouteContentWrapper>
        <Route
          {...rest}
          render={props => {
            return currentUser.data ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
          }
          }
        />
      </PrivateRouteContentWrapper>
    </>
  )
})

const Router = () => {
  return (
    <BrowserRouter>
      <Content>
        <Switch>
          <PrivateRoutePropsMapping path="/users" exact component={UserPagination}/>

          <PrivateRoutePropsMapping path="/simple-policies" exact component={PolicySimplePagination}/>

          <PrivateRoutePropsMapping path="/trade" exact component={Trade}/>

          <PrivateRoutePropsMapping path="/entities" exact component={EntityPagination}/>

          <PrivateRoutePropsMapping path="/positions" exact component={PositionsPagination}/>

          <PrivateRoutePropsMapping path="/jobs" exact component={JobPagination}/>

          <PrivateRoutePropsMapping path="/system" exact component={SettingUpsert}/>

          <PrivateRoutePropsMapping path="/migration" exact component={MigrationUpsert}/>

          <PrivateRoutePropsMapping path="/fee" exact component={FeeScheduleUpsert}/>

          <Route path="/login" component={Login}/>

          <Redirect from="/*" to="/users"/>
        </Switch>
      </Content>


    </BrowserRouter>
  )
}

export default Router
