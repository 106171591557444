import React, { useState, useEffect } from 'react'
import orderBy from 'lodash/orderBy'
import { getUpsertDefault, useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import entityApiService, {
  getEntities,
} from '../../service/entityApiService'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText  from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

import EntityACLUpsert from './EntityACLUpsert'
import EntitySystemACLUpsert from './EntitySystemACLUpsert'
import EntityBlockchainUpsert from './EntityBlockchainUpsert'
import DocumentsUpsert from '../common/DocumentsUpsert'
import EntityOffchainUpsert from './EntityOffchainUpsert'
import EntityOnchainUpsert from './EntityOnchainUpsert'
import EntityDistributionUpsert from './EntityDistributionUpsert'
import entityPaginationHandler from '../../handler/entityPaginationHandler'
import DocumentEnum from '../../enums/DocumentEnum'
import userApiService from '../../service/userApiService'
import { UpsertButtonsWrapper } from '../stylesComponents/Tags'
import AclRoleEnum from '../../enums/AclRoleEnum'
import toastrService from '../../service/toastrService'
import { updatePositions } from '../../service/positionsApiService'

const EntityUpsertWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const EntityCloseWrapper = styled.div`
  text-align: right;
  margin: 10px 15px;
`
const CloseIconWrapper = styled(CloseIcon)`
  cursor: pointer;
`

const StyledOnboardingStatus = styled.div`
  background: ${props => (props.onboarded ? 'green' : 'orange')};
  color: white;
  font-weight: bold;
  border-radius: 8px;
  white-space: nowrap;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default () => {
  const context = useGlobalContext()
  const { upsertEntity, setUpsertEntity } = context

  const isUpdate = upsertEntity.id

  const [entities, setEntities] = useState([])
  const [users, setUsers] = useState([])
  const [showWarningPositionDialog, setShowWarningPositionDialog] = useState(false);

  const init = async () => {
    setInitRequestStatus(RequestStatusEnum.loading)

    const entitiesPaginated = await getEntities({
      omitRoles: true,
      page: 0,
      rowsPerPage: 10000,
    })
    setEntities(
      orderBy(
        entitiesPaginated.data.map(item => {
          return {
            value: item.id,
            label: item.entityName,
          }
        }),
        ['label']
      )
    )

    const { data: allUsers = [] } = await userApiService.getAllUsers({
      skipParentEntity: true,
      skipAclRoles: true}
    )
    setUsers(
      orderBy(
        allUsers.map(item => {
          return {
            value: item.id,
            label: getUserLabel(item),
          }
        }),
        ['label']
      )
    )

    let upsertEntityClone = { ...upsertEntity }
    let entityDynamoDbWithDefault = null
    if (isUpdate) {
      entityDynamoDbWithDefault = await entityApiService.getEntity({
        id: upsertEntity.id,
      })
    }
    upsertEntityClone = {
      ...upsertEntityClone,
      data: entityDynamoDbWithDefault,
      initRequestStatus: RequestStatusEnum.success,
      show: true,
      onChain: entityDynamoDbWithDefault?.onChain || false,
    }
    setUpsertEntity(upsertEntityClone)
  }

  useEffect(() => {
    init()
  }, [])

  const getUserLabel = item => {
    const labels = []
    if (item.firstName) labels.push(item.firstName)
    if (item.lastName) labels.push(item.lastName)
    if (labels.length === 0) labels.push(item.address)
    return labels.join(' ')
  }

  const onClose = async () => {
    await entityPaginationHandler.onClearUpsertEntity(context)
    setUpsertEntity(getUpsertDefault())
  }

  const setInitRequestStatus = requestStatus => {
    setUpsertEntity({
      ...upsertEntity,
      initRequestStatus: requestStatus,
    })
  }

  const isCapitalProvider = () => {
    return upsertEntity?.data?.systemAclRole === AclRoleEnum.roleCapitalProvider
  }

  const onShowUpdatePositionsWarningDialog = () => {
    setShowWarningPositionDialog(true);
  }

  const handleClosePositionWarningDialog = () => {
    setShowWarningPositionDialog(false);
  }

  const handleApproveUpdatePositions = async () => {
    setShowWarningPositionDialog(false);
    await updatePositions({ entityId: upsertEntity.id })
  }

  const onCompleteOnboarding = async () => {
    const entityAdminId = upsertEntity?.data?.entityAdministratorId;
    if (!entityAdminId) {
      toastrService.error('Entity administrator ID is required');
      return;
    }

    const user = upsertEntity?.data?.userAclRoles[entityAdminId]?.user;
    if (!user) {
      toastrService.error('Entity administrator does not exist');
      return;
    }

    const validationErrors = [];
    if (!user.email) validationErrors.push(`Entity administrator 'Email' is required`);
    if (!user.firstName) validationErrors.push(`Entity administrator 'First name' is required`);
    if (!user.lastName) validationErrors.push(`Entity administrator 'Last name' is required`);
    if (!upsertEntity?.data?.entityName) validationErrors.push(`Entity 'Name' is required`);
    if (!upsertEntity?.data?.contactEmail) validationErrors.push(`Entity 'Contact Email' is required`);
    if (!upsertEntity?.data?.addressPrimary) validationErrors.push(`Entity 'Primary Address' is required`);
    if (!upsertEntity?.data?.city) validationErrors.push(`Entity 'City' is required`);
    if (!upsertEntity?.data?.zipCode) validationErrors.push(`Entity 'Zip Code' is required`);
    if (!upsertEntity?.data?.country) validationErrors.push(`Entity 'Country' is required`);

    if (upsertEntity?.data?.organizationType === 'CORPORATE_ENTITY') {
      if (!user.jobTitle) validationErrors.push(`Entity administrator 'Details' is required`);
      if (!upsertEntity?.data?.registrationJurisdiction) validationErrors.push(`Entity 'Jurisdiction of Registration' is required`);
      if (!upsertEntity?.data?.registeredCompanyNumber) validationErrors.push(`Entity 'Registered Company Number' is required`);
    }

    if (validationErrors.length > 0) {
      const allErrors = "Errors: <br /><ul>" + validationErrors.map(error => `<li>${error}</li>`).join('') + "</ul>";
      toastrService.error(allErrors);
    }

    try {
      const entityData = await entityApiService.completeOnboarding(upsertEntity.data.id);
      setUpsertEntity({
        ...upsertEntity,
        data: {
          ...upsertEntity.data,
          ...entityData,
        }
      })
    }
    catch (e) {
      toastrService.error(e);
    }
  }

  return (
    <EntityUpsertWrapper>
      <EntityCloseWrapper>
        <CloseIconWrapper onClick={onClose} />
      </EntityCloseWrapper>
      {isCapitalProvider() && (
        <>
          <UpsertButtonsWrapper>
            <StyledOnboardingStatus onboarded={upsertEntity?.data?.onboarded}>
              {upsertEntity?.data?.onboarded ? 'Onboarded' : 'Not onboarded'}
            </StyledOnboardingStatus>
            <Tooltip title="Complete onboarding">
              <div>
                <Button
                  disabled={upsertEntity?.data?.onboarded}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={onCompleteOnboarding}
                >
                  Complete Onboarding
                </Button>
              </div>
            </Tooltip>
            <Tooltip title="Update positions">
              <div>
                <Button
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={onShowUpdatePositionsWarningDialog}
                >
                  Update positions
                </Button>
              </div>
            </Tooltip>
          </UpsertButtonsWrapper>
        </>
      )}

      {upsertEntity.initRequestStatus === RequestStatusEnum.loading && (
        <p>Loading component goes here</p>
      )}

      {upsertEntity.initRequestStatus === RequestStatusEnum.success && (
        <>
          <EntityOffchainUpsert />

          {upsertEntity.id && <EntityOnchainUpsert />}

          {upsertEntity.onChain && (
            <>
              <DocumentsUpsert
                referenceId={upsertEntity.id}
                referenceType={DocumentEnum.referenceTypes.entity}
                relatedType={DocumentEnum.relatedTypes.entity}
                relatedToOptions={entities}
                documentTypeOptions={DocumentEnum.entityDocumentTypes}
              />
              <EntityACLUpsert entities={entities} users={users} />
              <EntitySystemACLUpsert />
            </>
          )}

          {upsertEntity.onChain && upsertEntity.data && (
            <>
              <EntityBlockchainUpsert />
              <EntityDistributionUpsert />
            </>
          )}
        </>
      )}

      <Dialog
        open={showWarningPositionDialog}
        onClose={handleClosePositionWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Update positions warning?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please use this feature only when advised to do so and only for Capital Provider entities.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePositionWarningDialog}>Cancel</Button>
          <Button onClick={handleApproveUpdatePositions} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </EntityUpsertWrapper>
  )
}
