import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import {
  useGlobalContext,
} from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'

import { getDefaultFieldValue } from '../../utils/formUtility'
import LoaderTransparent from '../common/LoaderTransparent'
import { getPositions } from '../../service/positionsApiService'
import entityApiService from '../../service/entityApiService'
import orderBy from 'lodash/orderBy'

import {
  TableContainerWrapper,
  TableRowWrapper,
  PaginationSearchWrapper,
  PaginationWrapper,
  TableCellWrapper,
  PaginationFormGroupsWrapper,
} from '../stylesComponents/PaginationCommon'
import { SelectFieldValuePaginationWrapper } from '../stylesComponents/UpsertCommon'
import TablePagination from '../common/TablePagination'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import { fromCrypto } from '../../utils/formatUtility'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const paginationFieldValuesDefault = {
  entity: getDefaultFieldValue(),
  searchValue: getDefaultFieldValue(),
  startDateMin: getDefaultFieldValue(),
  startDateMax: getDefaultFieldValue(),
}

export default () => {

  const {
    paginationPositions, setPaginationPositions
  } = useGlobalContext()

  const classes = useStyles()

  const [fieldValues, setFieldValues] = useState(paginationFieldValuesDefault)
  const [entities, setEntities] = useState([])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...fieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setFieldValues(fieldValuesClone)
  }

  const setFetchRequestStatus = requestStatus => {
    setPaginationPositions({
      ...paginationPositions,
      requestStatus,
    })
  }

  const fetchData = async ({ positionsClone }) => {
    const paginationResponse = await getPositions({
      searchText: fieldValues.searchValue.value,
      entityId: fieldValues.entity.value,
      filters: positionsClone.filters,
      page: positionsClone.page,
      rowsPerPage: positionsClone.rowsPerPage,
    })

    setPaginationPositions({
      ...positionsClone,
      count: paginationResponse.count,
      data: paginationResponse.data,
      requestStatus: RequestStatusEnum.success,
    })
  }

  useEffect(() => {
    setFetchRequestStatus(RequestStatusEnum.loading)
    loadEntities()
  }, [])

  useEffect(() => {
    if(fieldValues.entity?.value?.length > 0) {
      onFetchWithErrorHandler()
    }
  }, [fieldValues.entity])

  const loadEntities = async () => {
    const entities = await entityApiService.getEntities({
      page: 0,
      rowsPerPage: 10000,
    })

    setEntities(orderBy(entities.data, [entity => entity.entityName.toLowerCase()], ['asc']))
    
    const [ firstEntity ] = entities.data
    if(firstEntity) {
      setFieldValues({
        ...fieldValues,
        entity: { value: firstEntity.id} ,
      })
    }
  }

  const onFetchWithErrorHandler = async () => {
    const paginationPositionsClone = {
      ...paginationPositions,
      searchText: fieldValues.searchValue.value,
      entityId: fieldValues.entity.value,
      filters: {
        startDateMin: fieldValues.startDateMin.value,
        startDateMax: fieldValues.startDateMax.value,
      },
      page: 0,
      requestStatus: RequestStatusEnum.loading,
    }
    await setPaginationPositions(paginationPositionsClone)
    try {
      await fetchData({ positionsClone: paginationPositionsClone })
    } catch (e) {
      console.error('error', e)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const entityOptions = () => entities.map(entity => ({ 
    value: entity.id,
    label: entity.entityName 
  }))
  
  return (
  <PaginationWrapper>
    <LoaderTransparent active={paginationPositions.requestStatus === RequestStatusEnum.loading}>
      <PaginationSearchWrapper>

        <PaginationFormGroupsWrapper>
          <SelectFieldValuePaginationWrapper>
              <FieldValueSelect
                value={fieldValues.entity.value}
                options={ entityOptions() }
                name="entity"
                label="Entity"
                onFieldUpdate={ onFieldUpdate }
              />
          </SelectFieldValuePaginationWrapper>
        </PaginationFormGroupsWrapper>
     
        <TableContainerWrapper>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Owner</TableCell>
                  <TableCell>Asset ID</TableCell>
                  <TableCell>Asset Name</TableCell>
                  <TableCell>Asset Symbol</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginationPositions.data.map((position) => (
                  <TableRowWrapper key={position.assetId}>
                    <TableCellWrapper>{position.ownerId}</TableCellWrapper>
                    <TableCellWrapper>{position.assetId}</TableCellWrapper>
                    <TableCellWrapper>{position.assetName}</TableCellWrapper>
                    <TableCellWrapper>{position.assetSymbol}</TableCellWrapper>
                    <TableCellWrapper>{fromCrypto(position.amount, position.decimals || 18)}</TableCellWrapper>
                    <TableCellWrapper>{new Date(position.createdAt).toLocaleString()}</TableCellWrapper>
                    <TableCellWrapper>{new Date(position.updatedAt).toLocaleString()}</TableCellWrapper>
                  </TableRowWrapper>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination pagination={paginationPositions}
                            setPagination={setPaginationPositions}
                            fetchData={getPositions}/>

        </TableContainerWrapper>
      </PaginationSearchWrapper>
    </LoaderTransparent>
  </PaginationWrapper>
  )
}
