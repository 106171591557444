import { ethers } from 'ethers'
import { ERC20, Nayms } from '@nayms/contracts'
import deployments from '@nayms/contracts/gemforge.deployments.json'
import { signOut } from '../service/authenticationApiService'
import { refreshToken } from '../service/fetchService'

let provider
let signer
let readOnly = false
let requested = false
let signerAddress;

const addressFromTarget = (targetKey) => {
  if (!deployments[targetKey] || !deployments[targetKey].contracts) {
    console.error('Target or contracts not found');
    return;
  }
  const contracts = deployments[targetKey].contracts;
  for (const contract of contracts) {
    if (contract.name === 'DiamondProxy' && contract?.onChain?.address) {
      return contract.onChain.address;
    }
  }
  console.error(`Found no diamond for target '${targetKey}'`);
}

const networkToTarget = (network) => {
  switch(network.chainId) {
    case 1:
      return "mainnet"
    case 5:
      return "goerli"
    case 8453:
      return "base"
    case 84531:
      return "baseGoerli"
    case 84532:
      return "baseSepolia"
    case 11155111:
      return "sepolia"
    case 31337:
      return "local"
    default:
      return "sepolia"
  }
}

export const getNaymsContractAddress = async () => {
  const network = await getNetwork()
  return addressFromTarget(networkToTarget(network))
}

export const getNaymsContract = async () => {
  const deployerAddress = await getNaymsContractAddress()
  const signer = await getSigner()
  return new ethers.Contract(deployerAddress, Nayms, signer)
}

export const getInterface = async () => {
  return new ethers.utils.Interface(Nayms);
}

export const getERC20Contract = async (tokenAddress) => {
  const signer = await getSigner()
  return new ethers.Contract(tokenAddress, ERC20.abi, signer)
}

export async function setupWeb3({
                                  customProvider,
                                  reloadOnAccountsChange = true,
                                }) {
  if (provider) {
    return { provider, signer }
  }

  if (customProvider) {
    if (typeof customProvider === 'string') {
      // handle raw RPC endpoint URL
      provider = new ethers.providers.JsonRpcProvider(customProvider)
      signer = provider.getSigner()
    } else {
      // handle EIP 1193 provider
      provider = new ethers.providers.Web3Provider(customProvider)
    }
    return { provider, signer }
  }

  if (window && window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum)
    await provider.send('eth_requestAccounts', []); // ensure we connect wallet before setting up web3
    signer = provider.getSigner()
    signerAddress = await signer.getAddress();

    if (window.ethereum.on && reloadOnAccountsChange) {
      window.ethereum.on('accountsChanged', async function(accounts) {

        if (accounts.length === 0) {
          await signOut()
          window.location.pathname = '/login'
          return
        }

        if (signer) {
          // const address = await signer.getAddress()
          if (accounts[0] !== signerAddress) {
            await signOut()
            window.location.pathname = '/login'
          }
        }
      })

      window.ethereum.on('chainChanged', async chainId => {
        if (chainId) {
          let success = await refreshToken(`${parseInt(chainId, 16)}`)
          if (success)
            window.location.reload();
          else
            window.location.pathname = '/login'
        }
      });
    }
    return { provider, signer }
  } else if (window && window.web3 && window.web3.currentProvider) {
    provider = new ethers.providers.Web3Provider(window.web3.currentProvider)
    signer = provider.getSigner()
    return { provider, signer }
  } else {
    try {
      const url = 'http://localhost:8545'
      await fetch(url)
      console.log('local node active')
      provider = new ethers.providers.JsonRpcProvider(url)
    } catch (error) {
      if (
        error.readyState === 4 &&
        (error.status === 400 || error.status === 200)
      ) {
        // the endpoint is active
        console.log('Success')
      } else {
        console.log(
          'No web3 instance injected. Falling back to cloud provider.',
        )
        readOnly = true
        provider = new ethers.getDefaultProvider('homestead')
        return { provider, signer }
      }
    }
  }
}

export async function getWeb3() {
  if (!provider) {
    throw new Error(
      'Ethers has not been instantiated, please call setupWeb3() first',
    )
  }
  return provider
}

export async function getNetwork() {
  const provider = await getWeb3()
  return provider.getNetwork()
}

export function isReadOnly() {
  return readOnly
}

export async function getProvider() {
  return getWeb3()
}

export async function getSigner() {
  const provider = await getWeb3()
  try {
    const signer = provider.getSigner()
    await signer.getAddress()
    return signer
  } catch (e) {
    if (window.ethereum) {
      try {
        if (requested === true) return provider
        await window.ethereum.enable()
        const signer = await provider.getSigner()
        await signer.getAddress()
        return signer
      } catch (e) {
        requested = true
        return provider
      }
    } else {
      return provider
    }
  }
}

export async function getAccount() {
  const provider = await getWeb3()
  try {
    const signer = await provider.getSigner()
    return await signer.getAddress()
  } catch (e) {
    return '0x0'
  }
}

export async function getAccounts() {
  try {
    const account = await getAccount()
    if (parseInt(account, 16) !== 0) {
      return [account]
    } else if (window.ethereum) {
      try {
        const accounts = await window.ethereum.enable()
        return accounts
      } catch (error) {
        console.warn('Did not allow app to access dapp browser')
        throw error
      }
    } else {
      return []
    }
  } catch (e) {
    return []
  }
}

export async function getBlock() {
  try {
    const provider = await getWeb3()
    const block = await provider.getBlockNumber()
    const blockDetails = await provider.getBlock(block)
    return {
      number: blockDetails.number,
      timestamp: blockDetails.timestamp,
    }
  } catch (e) {
    console.log('error getting block details', e)
    return {
      number: 0,
      timestamp: 0,
    }
  }
}
