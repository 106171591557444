import {
  BoxWrapper,
  ButtonsWrapper,
  Flex,
  PositionRelative,
  Width800Wrapper,
} from '../stylesComponents/Tags'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import { getRequestStatusDefault } from '../../context/GlobalContext'
import {
  getUpsertFeeScheduleDefaultFieldValues,
  getUpsertFeeScheduleFieldValuesValidated,
} from '../../context/UpsertFeeScheduleFieldValues'
import { useEffect, useState } from 'react'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import { getEntities } from '../../service/entityApiService'
import orderBy from 'lodash/orderBy'
import styled from 'styled-components'
import Constants from '../../utils/constants'
import { getFeeSchedule } from '../../api/entityEtherApi'
import FieldValueBase from '../fieldValue/FieldValueBase'
import { UpsertWrapperWithScroll } from '../stylesComponents/UpsertCommon'
import LoaderTransparent from '../common/LoaderTransparent'
import Button from '@material-ui/core/Button'
import toastrService from '../../service/toastrService'
import { addFeeSchedule } from '../../api/adminEtherApi'

const Recipients = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`

const FlexWithGap = styled(Flex)`
  gap: 32px;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`

const LeftCell = styled.div`
  width: 660px;
`
const RightCell = styled.div`
  width: 120px;
`

const ButtonWrapperWithGap = styled(ButtonsWrapper)`
  gap: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const ButtonWrapperCentered = styled(ButtonsWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const RecipientsContainer = styled.div`
  width: 100%;
`

const sourceTypeOptions = orderBy(
  [
    { value: 1, label: 'Premium' },
    { value: 2, label: 'Trading' },
    { value: 3, label: 'Initial sale' },
  ],
  ['label']
)

export default () => {
  const [upsertFeeScheduleRequest, setUpsertFeeScheduleRequest] = useState(
    getRequestStatusDefault()
  )
  const [
    upsertFeeScheduleFieldValues,
    setUpsertFeeScheduleFieldValues,
  ] = useState(getUpsertFeeScheduleDefaultFieldValues())

  const [targetOptions, setTargetOptions] = useState([])

  const setRequestStatus = requestStatus => {
    setUpsertFeeScheduleRequest({
      ...upsertFeeScheduleRequest,
      initRequestStatus: requestStatus,
    })
  }

  const init = async () => {
    setRequestStatus(RequestStatusEnum.loading)

    const paginationResponse = await getEntities({
      page: 0,
      rowsPerPage: 10000,
    })

    const entityOptions = orderBy(
      paginationResponse.data.map(item => {
        return {
          value: item.id,
          label: item.entityName,
        }
      }),
      [option => option.label.toLowerCase()]
    )

    setTargetOptions(
      [
        {
          value: Constants.BYTES32_ZERO,
          label: 'Platform',
        },
      ].concat(entityOptions)
    )

    setRequestStatus(RequestStatusEnum.success)
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (
      !upsertFeeScheduleFieldValues.target.value ||
      !upsertFeeScheduleFieldValues.sourceType.value
    )
      return

    setRequestStatus(RequestStatusEnum.loading)

    getFeeSchedule(
      upsertFeeScheduleFieldValues.target.value,
      upsertFeeScheduleFieldValues.sourceType.value
    )
      .then(result => {
        const fieldValuesClone = { ...upsertFeeScheduleFieldValues }
        const recipients = result.receiver.map((receiver, i) => ({
          receiver,
          basisPoints: result.basisPoints[i],
        }))
        fieldValuesClone['recipients'] = {
          ...fieldValuesClone['recipients'],
          value: recipients,
        }

        setUpsertFeeScheduleFieldValues(fieldValuesClone)
        setRequestStatus(RequestStatusEnum.success)
      })
      .catch(e => {
        console.error(e)
        setRequestStatus(RequestStatusEnum.error)
      })
  }, [
    upsertFeeScheduleFieldValues.target.value,
    upsertFeeScheduleFieldValues.sourceType.value,
  ])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertFeeScheduleFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertFeeScheduleFieldValues(fieldValuesClone)
  }

  const isLoading = () => {
    return [
      upsertFeeScheduleRequest.submitRequestStatus,
      upsertFeeScheduleRequest.initRequestStatus,
    ].includes(RequestStatusEnum.loading)
  }

  const onClickRemoveRecipientWithErrorHandling = (recipient, index) => {
    try {
      const fieldValuesClone = { ...upsertFeeScheduleFieldValues }
      if (index >= 0) {
        fieldValuesClone['recipients'].value.splice(index, 1)
      }

      setUpsertFeeScheduleFieldValues(fieldValuesClone)
    } catch (e) {
      console.error(e)
      toastrService.error(e.message)
    }
  }

  const onUpdateRecipient = e => {
    const eventNameArray = e.target.name.split('-')

    const recipientIndex = eventNameArray[0]
    const name = eventNameArray[1]

    const premiumsValueClone = [
      ...upsertFeeScheduleFieldValues.recipients.value,
    ]
    premiumsValueClone[recipientIndex][name] = e.target.value

    const fieldValuesClone = {
      ...upsertFeeScheduleFieldValues,
      recipients: {
        ...upsertFeeScheduleFieldValues.recipients,
        value: premiumsValueClone,
      },
    }

    const { fieldValuesValidated } = getUpsertFeeScheduleFieldValuesValidated(
      fieldValuesClone
    )

    setUpsertFeeScheduleFieldValues(fieldValuesValidated)
  }

  const onClickAddRecipientWithErrorHandling = () => {
    if (
      !upsertFeeScheduleFieldValues.sourceType.value ||
      !upsertFeeScheduleFieldValues.target.value
    ) {
      toastrService.error('Please select Type and Target')
      return
    }

    const fieldValuesClone = {
      ...upsertFeeScheduleFieldValues,
      recipients: {
        ...upsertFeeScheduleFieldValues.recipients,
        value: [
          ...upsertFeeScheduleFieldValues.recipients.value,
          { receiver: '', basisPoints: '' },
        ],
      },
    }

    const { fieldValuesValidated } = getUpsertFeeScheduleFieldValuesValidated(
      fieldValuesClone
    )

    setUpsertFeeScheduleFieldValues(fieldValuesValidated)
  }

  const getEmptyRecipientFee = () => ({
    feeReceivers: [],
    feeBasisPoints: [],
  })

  const isNotNullish = x => x != null

  const addRecipientFee = (acc, { receiver, basisPoints }) => {
    if (!(receiver && isNotNullish(basisPoints))) return acc
    const { feeReceivers, feeBasisPoints } = acc
    return {
      feeReceivers: [...feeReceivers, receiver],
      feeBasisPoints: [...feeBasisPoints, basisPoints],
    }
  }

  const onClickSubmitWithErrorHandling = async () => {
    try {
      const {
        fieldValuesValidated,
        isValid,
      } = getUpsertFeeScheduleFieldValuesValidated(upsertFeeScheduleFieldValues)

      if (!isValid) {
        setUpsertFeeScheduleRequest({
          ...upsertFeeScheduleRequest,
          submitRequestStatus: RequestStatusEnum.error,
        })
        setUpsertFeeScheduleFieldValues(fieldValuesValidated)
        return
      }

      setUpsertFeeScheduleRequest({
        ...upsertFeeScheduleRequest,
        submitRequestStatus: RequestStatusEnum.loading,
      })

      const {
        feeReceivers,
        feeBasisPoints,
      } = fieldValuesValidated.recipients.value.reduce(
        addRecipientFee,
        getEmptyRecipientFee()
      )

      console.log({
        entityId: fieldValuesValidated.target.value,
        feeScheduleType: fieldValuesValidated.sourceType.value,
        receivers: feeReceivers,
        basisPoints: feeBasisPoints,
      })
      await addFeeSchedule(
        fieldValuesValidated.target.value,
        fieldValuesValidated.sourceType.value,
        feeReceivers,
        feeBasisPoints
      )
      toastrService.success("Updated Fee Schedule")

      setUpsertFeeScheduleRequest({
        ...upsertFeeScheduleRequest,
        submitRequestStatus: RequestStatusEnum.success,
      })
    } catch (e) {
      console.error(e)
      toastrService.error(e.message)
      setUpsertFeeScheduleRequest({
        ...upsertFeeScheduleRequest,
        submitRequestStatus: RequestStatusEnum.error,
      })
    }
  }

  const onClearForm = () => {
    setUpsertFeeScheduleFieldValues(getUpsertFeeScheduleDefaultFieldValues())
  }

  return (
    <UpsertWrapperWithScroll>
      <PositionRelative>
        <LoaderTransparent active={isLoading()}>
          <BoxWrapper>
            <h3>Update fee schedule</h3>
            <br />
            <Width800Wrapper>
              <FlexWithGap>
                <FieldValueSelect
                  value={upsertFeeScheduleFieldValues.sourceType.value}
                  options={sourceTypeOptions}
                  name="sourceType"
                  label="Type"
                  onFieldUpdate={onFieldUpdate}
                  withError
                  error={upsertFeeScheduleFieldValues.sourceType.error}
                />
                <FieldValueSelect
                  value={upsertFeeScheduleFieldValues.target.value}
                  name="target"
                  label="Target"
                  options={targetOptions}
                  withError
                  error={upsertFeeScheduleFieldValues.target.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </FlexWithGap>
            </Width800Wrapper>
            <br />
            <br />
            <h4>Recipients:</h4>
            <RecipientsContainer>
              <Recipients>
                {upsertFeeScheduleFieldValues?.recipients?.value &&
                  upsertFeeScheduleFieldValues.recipients.value.map(
                    (recipient, index) => (
                      <FlexRow key={index.toString()}>
                        <LeftCell>
                          <FieldValueBase
                            value={recipient.receiver}
                            name={`${index}-receiver`}
                            label="ID"
                            withError
                            onFieldUpdate={onUpdateRecipient}
                            fullWidth
                          />
                        </LeftCell>
                        <RightCell>
                          <FieldValueBase
                            value={recipient.basisPoints}
                            name={`${index}-basisPoints`}
                            label="Basis Points"
                            withError
                            onFieldUpdate={onUpdateRecipient}
                          />
                        </RightCell>
                        <ButtonWrapperCentered>
                          <Button
                            variant="outlined"
                            size="medium"
                            color="primary"
                            onClick={() =>
                              onClickRemoveRecipientWithErrorHandling(
                                recipient,
                                index
                              )
                            }
                          >
                            Remove
                          </Button>
                        </ButtonWrapperCentered>
                      </FlexRow>
                    )
                  )}
              </Recipients>
            </RecipientsContainer>
            <ButtonWrapperWithGap>
              <Button
                variant="text"
                size="medium"
                color="primary"
                onClick={onClickAddRecipientWithErrorHandling}
              >
                Add Recipient
              </Button>
            </ButtonWrapperWithGap>
            <ButtonWrapperWithGap>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={onClickSubmitWithErrorHandling}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                onClick={onClearForm}
              >
                Clear
              </Button>
            </ButtonWrapperWithGap>
          </BoxWrapper>
        </LoaderTransparent>
      </PositionRelative>
    </UpsertWrapperWithScroll>
  )
}
